.transition_container {
    position: absolute;
    z-index: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10vh;
    width: 100vw;
    height: 100vh;
    background: var(--background);
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity 2s ease-in-out;

    & img {
        width: 132px;
        height: auto;
    }
}

.animation {
    animation: waving 4s infinite;
}

.visible {
    opacity: 1;
}

@keyframes waving {
    0%,
    100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5%);
    }
    50% {
        transform: translateX(5%);
    }
    75% {
        transform: translateX(-5%);
    }
}
