.navbar {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 22vw;
    background-color: var(--secondary-background);
    height: 94vh;
    border-radius: var(--large-border-radius);
    transition: background-color var(--transition-duration);
}

.navbar_top {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 16px;
    margin-top: 4px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    margin-left: 8px;
    margin-right: 8px;
}

.logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--text);

    & img {
        width: 36px;
        height: auto;
        margin-right: 4px;
    }
}

.links {
    display: flex;
}

.anchor_icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--text);
    text-decoration: none;
    margin-left: 8px;
    cursor: pointer;
}

.navbar_items {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 0px;
    overflow-y: scroll;
}

.subheading {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--dark);
    padding: 0px 24px;
    margin-top: 16px;
    margin-bottom: 4px;
}

.item {
    font-size: 14px;
    font-weight: 400;
    border-radius: var(--small-border-radius);
    margin: 0px 16px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color var(--transition-duration);
}

.item:hover {
    background-color: var(--light);
    color: var(--blue);
}

.active {
    font-size: 14px;
    font-weight: 400;
    color: var(--blue);
    border-radius: var(--small-border-radius);
    margin: 0px 16px;
    padding: 8px 16px;
    cursor: pointer;
    background-color: var(--light);
    transition: background-color var(--transition-duration);
}

.empty_text {
    text-align: center;
    margin: 64px 24px;
}

.anchor {
    color: var(--blue);
}

.rotate {
    transform: rotate(45deg);
}
