.copy_button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: var(--code-header-text);
    border: none;
    background-color: transparent;
    cursor: pointer;
}
