.body {
    margin-left: 22vw;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 48px;
}

.body_gap {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.body_item {
    border: 2px solid var(--border);
    border-radius: var(--large-border-radius);
    padding: 24px 32px;
    transition: border var(--transition-duration);
}
