.code_viewer {
    border-radius: var(--medium-border-radius);
    margin: 16px 0px;
    overflow: hidden;

    & pre {
        padding: 8px 12px !important;
        & code,
        & span {
            font-family: "Courier New", Courier, monospace !important;
            font-size: 14px !important;
            font-weight: 400 !important;
        }
    }
}

.code_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    font-size: 12px;
    color: var(--code-header-text);
    padding: 8px 12px;
    background-color: var(--code-header);
    transition: background-color var(--transition-duration);

    & p {
        padding: 0;
    }
}
